import React, { useState, useEffect, useContext } from 'react';
import TableWithExtensions from '../components/TableWithExtensions';
import { MemoizedFilters } from '../components/Filters';
import CustomPagination from '../components/ui/CustomPagination';
import PageTitle from '../components/ui/PageTitle';
import { BiCalendarStar, BiImage, BiVideo } from 'react-icons/bi';
import { getDevices, getEventTypes, getMessages } from '../service/fleetsenseApiClient';
import { convertISODateStringToFormattedDateString } from '../utils/dateUtil';
import { buildFiltersString } from '../utils/filtersUtil';
import TableDataContext from '../context/tableDataContext';
import { map, pick, filter } from 'lodash';
import { Col, Container, Row } from 'react-bootstrap';

const Events = (props) => {

    //TODO - fetch unique values per column for the filters data
    const columns = [
        {
            column: "deviceID",
            displayName: "Unit ID",
            type: "multiselect",
            valueType: "string",
            width: 10, //in precentage
            filterWidth: 15.8,
            placeholder: "Unit ID",
            filterable: true,
            filterOptions: [],
            sortable: true
        },

        {
            column: "driverID",
            displayName: "Driver ID",
            type: "input",
            valueType: "string",
            width: 10, //in precentage
            filterWidth: 15.8,
            placeholder: "Driver ID",
            filterable: true,
            filterOptions: [],
            sortable: true
        },
        {
            column: "tripID",
            displayName: "Trip ID",
            type: "input",
            valueType: "number",
            width: 6, //in precentage
            filterWidth: 15.8,
            placeholder: "Trip ID",
            filterable: true,
            filterOptions: [],
            hidden: false,
            sortable: true
        },
        {
            column: "eventType",
            displayName: "Event Type",
            type: "multiselect",
            width: 15, //in precentage
            filterWidth: 15.8,
            valueType: "string",
            placeholder: "Event Type",
            filterable: true,
            filterOptions: [],
            sortable: true
        },
        {
            column: "eventDateTime",
            displayName: "Time",
            type: "date",
            valueType: "string",
            width: 15, //in precentage
            filterWidth: 26,
            placeholder: "Time",
            filterable: true,
            filterOptions: [],
            hidden: false,
            sortable: true
        },
        {
            column: "location",
            displayName: "Location",
            type: "dropdown",
            valueType: "string",
            width: 41.8, //in precentage
            filterWidth: 41.8,
            placeholder: "Location",
            filterable: false,
            filterOptions: []
        }
    ];


    const rowsPerPage = 10;

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsData, setRowsData] = useState([]);
    const [currentFiltersString, setCurrentFiltersString] = useState("");
    const [currentSorts, setCurrentSorts] = useState("");
    const [currentSortOrder, setCurrentSortOrder] = useState("");
    const [currentSortsString, setCurrentSortsString] = useState("-eventDateTime");
    const [totalRows, setTotalRows] = useState(0);
    const [eventTypes, setEventTypes] = useState([]);
    const [unitIds, setUnitIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { setFetchCallback, setFetchCallbackParams, updateEventsData } = useContext(TableDataContext);

    useEffect(() => {
        setIsLoading(true);
        unitIdsApiCall();
        eventTypesApiCall();
        setFetchCallback(() => exportDataApiCall);
    }, []);

    useEffect(() => {
        fetchDataFlow(currentFiltersString, currentSortsString);
    }, [currentPage, currentFiltersString, currentSortsString]);

    useEffect(() => {
        if (currentPage !== 1) {
            setCurrentPage(1);
        } else {
            fetchDataFlow(currentFiltersString, currentSortsString);
        }
    }, [updateEventsData]);

    useEffect(() => {
        setCurrentPage(1);
    }, [currentFiltersString]);

    useEffect(() => {
        if (currentSorts) {
            setCurrentSortsString(`${currentSortOrder}${currentSorts}`);
        }
    }, [currentSorts, currentSortOrder]);


    const fetchDataFlow = (currentFiltersString, currentSortsString) => {
        setIsLoading(true);
        dataApiCall();
        setFetchCallbackParams([null, null, currentFiltersString, currentSortsString]);
    }

    const eventTypesApiCall = async () => {
        const res = await getEventTypes();
        const data = res.data;

        setEventTypes(data);
    };

    const unitIdsApiCall = async () => {
        const res = await getDevices();
        const data = res.data;
        const unitIds = data.map(d => d.id).sort();

        setUnitIds(unitIds);
    };

    const dataApiCall = async () => {
        let res;

        try {
            res = await getMessages(currentPage, rowsPerPage, currentFiltersString, currentSortsString);
            const data = res.data;
            const header = res.header;

            setTotalRows(header.totalCount);
            setRowsData(data);
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    };

    const exportDataApiCall = async (page, pageSize, filters, sorts) => {
        const res = await getMessages(page, pageSize, filters, sorts, false);
        const data = res.data;

        return prepareDataForExport(data);
    };

    const prepareData = data => {
        const columnsKeys = map(columns, col => col.column);
        return map(data, dataRow => {
            const eventDataRow = pick(dataRow, columnsKeys);
            eventDataRow.eventDateTime = convertISODateStringToFormattedDateString(dataRow.eventDateTime);
            eventDataRow.location = dataRow.lastKnownStreetAddress;
            eventDataRow.eventType = (
                <div className="d-flex justify-content-between">

                    {dataRow.eventType}

                    <div>
                        {Boolean(dataRow.cabinVideoCount || dataRow.roadVideoCount) && <BiVideo style={{ padding: '0px 2px 0px 2px' }} />}
                        {Boolean(dataRow.cabinImageCount || dataRow.roadImageCount) && <BiImage style={{ padding: '0px 2px 0px 2px' }} />}
                    </div>
                </div>)

            return eventDataRow;
        });
    }

    const prepareDataForExport = data => {
        const flatData = data.map(row => {

            //escape special csv characters 
            row.lastKnownStreetAddress = row.lastKnownStreetAddress.replace(/"/g, '""')
            row.lastKnownStreetAddress = row.lastKnownStreetAddress.replace(/,/g, '\,');
            row.lastKnownStreetAddress = row.lastKnownStreetAddress.replace(/'/g, '\'');

            //split iOArrayStatus into columns
            if (row.iOArrayStatus) {
                const convertedIOArrayStatus = {};
                row.iOArrayStatus.forEach(input => {
                    convertedIOArrayStatus[input.portId] = JSON.stringify(input).replace(/\{|\}|"/g, '').replaceAll(',', ' | ');
                });
                row.iOArrayStatus = convertedIOArrayStatus;
            }

            return row;
        });

        return flatData;
    }

    const populateFetchedFiltersOptions = columns => {
        filter(columns, c => c.column === 'eventType')[0].filterOptions = eventTypes;
        filter(columns, c => c.column === 'deviceID')[0].filterOptions = unitIds;
        return columns;
    }

    const onGoToPageCallback = pageNum => {
        setCurrentPage(pageNum);
    }

    const onFiltersChangedCallback = filters => {
        setCurrentFiltersString(buildFiltersString(columns, filters));
    }

    const onSortByCallback = columnName => {
        setCurrentSorts(columnName);
    }

    const onSortOrderCallback = order => {
        setCurrentSortOrder(order === 'DESC' ? "-" : "");
    }

    return (
        <div className="events-container" style={{ display: 'flex', flexDirection: 'column' }}>
            <PageTitle title={"EVENTS"} variant={"primary"} >
                <BiCalendarStar />
            </PageTitle>
            <MemoizedFilters columns={populateFetchedFiltersOptions(columns)} onFiltersChangedCallback={onFiltersChangedCallback} />
            <TableWithExtensions
                columns={columns}
                rowsData={prepareData(rowsData)}
                rowsFullData={rowsData}
                popoverColumnIndex={3}
                onSortByCallback={onSortByCallback}
                onSortOrderCallback={onSortOrderCallback}
                initSortBy={currentSorts}
                initSortOrder={currentSorts ? (currentSortOrder ? 'DESC' : 'ACS') : ''}
                currentPage={currentPage}
                isLoading={isLoading} />
            {!isLoading && <CustomPagination total={totalRows} perPage={rowsPerPage} currentPage={currentPage} onGoToPageCallback={onGoToPageCallback} />}
        </div>
    );

}

export default Events;